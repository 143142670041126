<template>
  <div class="pass-history-view v3-pass-history">
    <Loader :is-processing="state.isLoading" />
    <ActionPanel
      class="mb-3"
      show-sort
      show-top-left-slot
      @on-search="searchByQuery"
      :search-v-model="state.watchSearchQuery"
      @on-sort="toggleAdvancedFiltersPanel"
    >
      <template #first-top-left-content>
        <LazyLoadSelect
          class="width-350-px"
          :selected="state.filterParams.by_student"
          type="student"
          placeholder="Student"
          :invalid-feedback="errors.by_student"
          :show-inactive="true"
          @changed="
            (value) => {
              state.filterParams.by_student = value
            }
          "
        ></LazyLoadSelect>
      </template>
      <template #right-content>
        <div
          @click="handleContactTracing(state.pagination.activePage, 'csv')"
          class="d-flex align-items-center me-1"
          :class="{ 'cursor-pointer': !exportLoadingFor('csv') }"
        >
          <IconButton
            class="static"
            :class="{ loading: exportLoadingFor('csv') }"
            :disabled="exportLoadingFor('csv')"
            :icon="
              exportLoadingFor('csv')
                ? `ri-loader-4-line`
                : `ri-download-2-line`
            "
          ></IconButton>
          <span>Download CSV</span>
        </div>
        <div
          @click="handleContactTracing(state.pagination.activePage, 'excel')"
          class="d-flex align-items-center me-1"
          :class="{ 'cursor-pointer': !exportLoadingFor('excel') }"
        >
          <IconButton
            class="static"
            :class="{ loading: exportLoadingFor('excel') }"
            :disabled="exportLoadingFor('excel')"
            :icon="
              exportLoadingFor('excel')
                ? `ri-loader-4-line`
                : `ri-download-2-line`
            "
          ></IconButton>
          <span>Download Excel</span>
        </div>
        <div
          @click="handleContactTracing(state.pagination.activePage, 'print')"
          class="d-flex align-items-center me-3"
          :class="{ 'cursor-pointer': !exportLoadingFor('print') }"
        >
          <IconButton
            class="static"
            :class="{ loading: exportLoadingFor('print') }"
            :disabled="exportLoadingFor('print')"
            :icon="
              exportLoadingFor('print')
                ? `ri-loader-4-line`
                : `ri-download-2-line`
            "
          ></IconButton>
          <span>Print</span>
        </div>
      </template>
      <template #right-last-content>
        <Dropdown
          auto-close="outside"
          @update:model-value="onColumnOptionsChange"
          :items="tableDropdowns.column"
          :check-list-mode="true"
          class="left-box-separator"
        >
          <template #toggler>
            <div class="d-flex align-items-center">
              <IconButton class="static" icon="ri-table-line"></IconButton>
              <span>Columns</span>
            </div>
          </template>
        </Dropdown>
      </template>

      <template #bottom-content>
        <div class="mt-3" v-if="state.isAdFiltersPanelVisible">
          <div class="d-flex mb-3">
            <CustomSchoolYearSelectDatePicker
              class="pt-selector ms-1"
              v-model="state.filterParams.dates"
              :range-options="optionalDateRanges"
              @update:model-value="selectRange"
            ></CustomSchoolYearSelectDatePicker>

            <div class="action-panel-search">
              <InputField
                class="flex-1 ms-1 me-2"
                prepend-icon="ri-search-line"
                placeholder="Search"
                :model-value="state.watchSearchQuery"
                @update:model-value="searchByQuery"
              ></InputField>
            </div>
          </div>
          <div class="justify-content-end text-end">
            <BaseButton class="" solid rounded @click="advancedSearch()"
              >Search</BaseButton
            >
            <BaseButton class="ms-2" rounded @click="clearFilter()"
              >Clear all</BaseButton
            >
          </div>
        </div>
      </template>
    </ActionPanel>

    <PerPage
      v-if="state.pagination && state.pagination.total > 0"
      :pagination="state.pagination"
      :showing-entries="true"
      @on-page-change="setPerPage"
    />

    <DataTable
      v-if="contactTracings"
      class="datatable bg-white text-center mt-3"
      add-table-classes="contactTracingDataTable"
      :fields="activeDataTableFields"
      :items="contactTracings"
      :sorter="{ external: true }"
      column-filter
      :no-items-view="{
        noResults: 'No items available',
        noItems: 'No items available'
      }"
      @update:sorter-value="sortByColumn"
    >
      <template #search_column>
        <div class="sort-icon-v3">
          <i class="ri-search-line" @click="toggleSearchByColumn"></i>
        </div>
      </template>

      <template #student_sis_id="{ item }">
        <td>
          <div>{{ item.student_sis_id ? item.student_sis_id : "null" }}</div>
        </td>
      </template>

      <template #first_name="{ item }">
        <td>{{ item.first_name }}</td>
      </template>

      <template #first_name-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.first_name.value"
          placeholder="Search by first name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #last_name="{ item }">
        <td>{{ item.last_name }}</td>
      </template>

      <template #last_name-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.last_name.value"
          placeholder="Search by last name"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #email="{ item }">
        <td>{{ item.email }}</td>
      </template>

      <template #email-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.email.value"
          placeholder="Search by email"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #overlap_time_count-filter>
        <input
          v-show="state.filter"
          v-model="state.columnSearchKeys.overlap_time_count.value"
          placeholder="Search by number"
          class="form-control form-control-sm my-2"
          @input="searchByQuery($event.target.value, 'column')"
        />
      </template>

      <template #share_same_location="{ item }">
        <td>{{ item.share_same_location ? "Yes" : "No" }}</td>
      </template>
    </DataTable>

    <Pagination
      v-if="state.pagination.pages > 1 && !state.isLazyLoadingMode"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
    <iframe
      class="opacity-0"
      name="print_frame"
      width="0"
      height="0"
      frameborder="0"
      src="about:blank"
    />
  </div>
</template>

<script>
import { reactive, onMounted, onUnmounted, computed, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import helpersJS from "@/helpers/index"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import Dropdown from "@/v3components/shared/Buttons/Dropdown.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import CustomSchoolYearSelectDatePicker from "@/v3components/shared/Form/CustomSchoolYearSelectDatePicker.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import DataTable from "@/v3components/shared/DataTable/DataTable"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import * as XLSX from "xlsx/xlsx.mjs"
import download from "@/helpers/downloadCSV"

export default {
  name: "ContactTracingTable",
  components: {
    Loader,
    ActionPanel,
    Dropdown,
    IconButton,
    BaseButton,
    LazyLoadSelect,
    CustomSchoolYearSelectDatePicker,
    InputField,
    PerPage,
    DataTable,
    Pagination
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")
    const state = reactive({
      isLazyLoadingMode: false,
      isLoading: false,
      export: {
        isLoading: false,
        csv: false,
        excel: false,
        print: false
      },
      isMounted: false,
      isAdFiltersPanelVisible: true,
      showStudentNumber: false,
      filter: false,
      filterParamsDateRange: {
        start: moment().format("MM/DD/YYYY"),
        end: moment().format("MM/DD/YYYY")
      },
      searchTimeOut: null,
      watchSearchQuery: "",
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      filterParams: {
        search_id: null,
        search_query: "",
        sort_query: "",
        by_student: null,
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      },
      columnSearchKeys: {
        globalQuery: "",
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        overlap_time_count: {
          value: "",
          column: ["overlap_time_count"],
          sortBy: "overlap_time_count"
        },
        share_same_location: {
          value: "",
          column: ["share_same_location"],
          sortBy: "share_same_location"
        }
      },
      serverErrors: {},
      serverRes: null
    })

    const tableDropdowns = {
      column: [
        {
          value: "student_id",
          label: "Student ID"
        }
      ]
    }

    const dataTableFields = [
      {
        key: "student_sis_id",
        filter: false,
        sorter: false,
        label: "Student number",
        _style: "min-width:150px"
      },
      { key: "last_name", label: "Last name", _style: "width:180px" },
      { key: "first_name", label: "First name", _style: "width:180px" },
      { key: "email", label: "Email", _style: "width:150px" },
      {
        key: "overlap_time_count",
        label: "Number of times passes overlapped",
        _style: "min-width:260px"
      },
      {
        key: "share_same_location",
        label: "Did they share a location?",
        _style: "min-width:210px",
        filter: false
      }
    ]

    const activeDataTableFields = computed(() => {
      return dataTableFields.filter((field) =>
        state.showStudentNumber == true ? field : field.key !== "student_sis_id"
      )
    })

    const student = computed(() => {
      return store.getters["users/formListStudents"]
    })

    const contactTracings = computed(() => {
      return store.getters["contactTracing/contactTracings"]
    })

    const minDate = computed(() => {
      return helpersJS.minSchoolYearDate()
    })

    const maxDate = computed(() => {
      return helpersJS.maxSchoolYearDate()
    })

    const fromDate = computed(() => {
      return state.filterParamsDateRange
        ? state.filterParamsDateRange.start
        : null
    })

    const toDate = computed(() => {
      return state.filterParamsDateRange
        ? state.filterParamsDateRange.end
        : null
    })

    const optionalDateRanges = computed(() => {
      return [
        {
          label: "Today",
          id: "",
          range: helpersJS.stringDateRange(moment(), moment())
        },
        {
          label: "Yesterday",
          id: "",
          range: helpersJS.stringDateRange(
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          )
        },
        {
          label: "Last 7 days",
          id: "",
          range: helpersJS.stringDateRange(
            moment().subtract(7, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(7, "days"),
            moment()
          )
        },
        {
          label: "Last 14 days",
          id: "",
          range: helpersJS.stringDateRange(
            moment().subtract(14, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(14, "days"),
            moment()
          )
        },
        {
          label: "Last 30 days",
          id: "",
          range: helpersJS.stringDateRange(
            moment().subtract(30, "days") < moment(minDate.value)
              ? moment(minDate.value)
              : moment().subtract(30, "days"),
            moment()
          )
        },
        {
          label: "This month",
          id: "",
          range: helpersJS.stringDateRange(
            moment().startOf("month"),
            moment().endOf("month")
          )
        },
        {
          label: "This year",
          id: "",
          range:
            helpersJS.currTzDate(minDate.value) +
            " - " +
            helpersJS.currTzDate(maxDate.value)
        },
        {
          label: "Custom range",
          range: null,
          id: "custom"
        }
      ]
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      filterParams: {
        by_student: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.filterParams, state.filterParams)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      state.isMounted = true
      initLazyLoading()
      handleContactTracing()
    })

    onUnmounted(() => {
      state.isMounted = false
    })

    const toggleAdvancedFiltersPanel = () => {
      state.isAdFiltersPanelVisible = !state.isAdFiltersPanelVisible
    }

    const selectRange = (range) => {
      state.filterParamsDateRange = {
        start: range.substring(0, range.indexOf(" -")),
        end: range.substring(range.indexOf("- ") + 2)
      }
    }

    const onColumnOptionsChange = (e) => {
      state.showStudentNumber = e && e.length ? true : false
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleContactTracing()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            contactTracings &&
            contactTracings.value.length &&
            state.pagination.total > contactTracings.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleContactTracing(
                state.pagination.activePage,
                false,
                true,
                false,
                false
              )
            }
          }
        }
      }
    }

    const handleContactTracing = (
      page,
      isExport,
      isLazyLoadingMode,
      isAdvancedSearch,
      noLoader = true
    ) => {
      state.isLoading = !noLoader
      if (state.filterParams.by_student) {
        state.pagination.activePage = page ? page : 1
        const params = {
          per_page: state.pagination.per_page.value,
          page: state.pagination.activePage
        }
        if (state.filterParams.search_query) {
          params.search_query = state.filterParams.search_query
        }
        if (state.filterParams.sort_query) {
          params.sort = state.filterParams.sort_query
        }
        if (state.filterParams.search_id) {
          params.search_id = state.filterParams.search_id
        }
        if (isAdvancedSearch || isExport) {
          params.user_id = state.filterParams.by_student.value.id
          params.from_date = helpersJS.isPreviousYearDate(fromDate.value)
            ? helpersJS.currTzDate(helpersJS.minSchoolYearDate())
            : fromDate.value
          params.to_date = helpersJS.isPreviousYearDate(toDate.value)
            ? helpersJS.currTzDate(helpersJS.minSchoolYearDate())
            : toDate.value
        }
        if (isExport) {
          generateExport(isExport, params)
        } else {
          getContactTracings(params, isLazyLoadingMode, isAdvancedSearch)
        }
      } else {
        state.isLoading = false
        store.commit("contactTracing/SET_CONTACT_TRACINGS", [])
      }
    }

    const getContactTracings = (data, isLazyLoadingMode, isAdvancedSearch) => {
      if (isAdvancedSearch) {
        state.isLoading = true
        store
          .dispatch("contactTracing/getContactTracing", data)
          .then((response) => {
            const responseData = response.data.data
            if (responseData && responseData.search_id) {
              state.filterParams.search_id = responseData.search_id
              const params = { ...data, search_id: responseData.search_id }
              getContactTracingResults(params, isLazyLoadingMode)
            }
          })
          .catch((error) => {
            state.isLoading = false
            if (
              error?.response?.data?.error.status_code == 504 &&
              state.isMounted
            ) {
              modal.open(InfoBox, {
                size: "md",
                title: "Something went wrong!",
                props: {
                  message:
                    "Search criteria is too large. Please reduce the date range and try again."
                }
              })
            }
          })
      } else {
        if (state.filterParams.search_id) {
          getContactTracingResults(data, isLazyLoadingMode)
        }
      }
    }

    const getContactTracingResults = (params, isLazyLoadingMode) => {
      state.isLoading = true
      store
        .dispatch("contactTracing/getContactTracingSearchResults", params)
        .then((response) => {
          const data = response.data
          if (data.data) {
            if (isLazyLoadingMode) {
              store.commit("contactTracing/PUSH_CONTACT_TRACINGS", data.data)
            } else {
              store.commit("contactTracing/SET_CONTACT_TRACINGS", data.data)
            }
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const exportLoadingFor = (type) => {
      return state.export.loading && state.export[type]
    }

    const generateExport = (type, params) => {
      if (state.export.loading) {
        return
      }
      state.export.loading = true
      state.export[type] = true
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      params.export_as = type == "excel" ? "print" : type
      params.student_sis_id = state.showStudentNumber
      if (type == "csv") {
        params.search_id = state.filterParams.search_id
          ? state.filterParams.search_id
          : ""
      }
      store
        .dispatch("contactTracing/exportContactTracing", params)
        .then((response) => {
          state.export.loading = false
          state.export[type] = false
          if (response.data) {
            if (type == "print") {
              printContactTracings(response.data.data)
            } else {
              if (type === "excel") {
                excelExport(response.data)
              } else {
                download.CSVExport(response.data, "MeetUpReport")
              }
            }
          }
        })
    }

    const excelExport = (data) => {
      if (data.data) {
        let table = document.getElementsByClassName("contactTracingDataTable")
        if (table && table[0]) {
          let tableHTML = "<table><thead>"
          const headers = `<tr>${
            state.showStudentNumber == true ? "<th>Student number</th>" : ""
          }<th>Last Name</th><th>First Name</th><th>Email</th><th>Number of times passes overlapped</th><th>Did they share a location?</th></tr>`
          table = table[0].cloneNode(true)
          tableHTML = tableHTML + headers + "</thead><tbody>"
          data.data.forEach((el) => {
            const studentNumber =
              state.showStudentNumber == true
                ? "<td>" + el.student_sis_id + "</td>"
                : ""
            tableHTML =
              tableHTML +
              `<tr>` +
              studentNumber +
              `<td>${el.last_name}</td><td>${el.first_name}</td><td>${
                el.email
              }</td><td>${el.overlap_time_count}</td><td>${
                el.share_same_location ? "Y" : "N"
              }</td></tr>`
          })
          table.innerHTML = tableHTML
          const workbook = XLSX.utils.table_to_book(table)
          const ws = workbook.Sheets["Sheet1"]
          XLSX.utils.sheet_add_aoa(
            ws,
            [["Created " + new Date().toISOString()]],
            { origin: -1 }
          )
          XLSX.writeFile(workbook, "MeetUpReport.xlsx")
        }
      }
    }

    const printContactTracings = (data) => {
      let tableHTML =
        `<html><head><title></title></head><body class="p-2"><h1>` +
        document.title +
        `</h1><table class="print-table"><thead>`
      const headers = `<tr>${
        state.showStudentNumber == true ? "<th>Student number</th>" : ""
      }<th>Last Name</th><th>First Name</th><th>Email</th><th>Number of times passes overlapped</th><th>Did they share a location?</th></tr>`
      tableHTML = tableHTML + headers + "</thead><tbody>"
      data.forEach((el) => {
        tableHTML =
          tableHTML +
          `<tr>${
            state.showStudentNumber == true
              ? "<td>" + el.student_sis_id + "</td>"
              : ""
          }<td>${el.last_name}</td><td>${el.first_name}</td><td>${
            el.email
          }</td><td>${el.overlap_time_count}</td><td>${
            el.share_same_location ? "Y" : "N"
          }</td></tr>`
      })
      tableHTML =
        tableHTML +
        `</tbody></table><style>.print-table{border-spacing: 0px;}
                .print-table th, .print-table td{border: 1px solid #333;border-color: #333; border-spacing: 0px; padding: 5px;border-left-width: 0;}
                .print-table td{ border-top: none;},
                .print-table tr{border-spacing: 0px !important;} .print-table td:first-child, .print-table th:first-child {border-left-width: 1;};
          </style></body>`
      window.frames["print_frame"].document.body.innerHTML = tableHTML
      window.frames["print_frame"].window.focus()
      window.frames["print_frame"].window.print()

      return true
    }

    const advancedSearch = () => {
      if (isFormValid.value) {
        resetSearchQuery()
        handleContactTracing(1, false, false, true)
      } else {
        v$.value.$touch()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleContactTracing(state.pagination.activePage)
      helpersJS.scrollToTop()
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  const searchValue =
                    col === "overlap_time_count"
                      ? columnData.value
                      : '"' + columnData.value + '"'
                  state.filterParams.search_query =
                    state.filterParams.search_query + `${col}:${searchValue}, `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.watchSearchQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handleContactTracing()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handleContactTracing()
    }

    const clearFilter = () => {
      v$.value.$reset()
      clearFilterParams()
      searchByQuery("")
      handleContactTracing()
    }

    const clearFilterParams = () => {
      state.filterParams = Object.assign(state.filterParams, {
        search_id: null,
        search_query: "",
        sort_query: "",
        by_student: null,
        dates:
          moment().format("MM/DD/YYYY") + " - " + moment().format("MM/DD/YYYY")
      })
      state.filterParamsDateRange = {
        start: moment().format("MM/DD/YYYY"),
        end: moment().format("MM/DD/YYYY")
      }
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        student_sis_id: {
          value: "",
          column: ["student_sis_id"],
          sortBy: "student_sis_id"
        },
        last_name: { value: "", column: ["last_name"], sortBy: "last_name" },
        first_name: { value: "", column: ["first_name"], sortBy: "first_name" },
        email: { value: "", column: ["email"], sortBy: "email" },
        overlap_time_count: {
          value: "",
          column: ["overlap_time_count"],
          sortBy: "overlap_time_count"
        },
        share_same_location: {
          value: "",
          column: ["share_same_location"],
          sortBy: "share_same_location"
        }
      }
    }

    return {
      state,
      tableDropdowns,
      dataTableFields,
      activeDataTableFields,
      student,
      contactTracings,
      minDate,
      maxDate,
      optionalDateRanges,
      v$,
      isFormValid,
      errors,
      toggleAdvancedFiltersPanel,
      selectRange,
      onColumnOptionsChange,
      toggleSearchByColumn,
      setPerPage,
      exportLoadingFor,
      advancedSearch,
      setActivePage,
      handleContactTracing,
      searchByQuery,
      sortByColumn,
      clearFilter
    }
  }
}
</script>

<style lang="scss" scoped>
.contactTracingDataTable thead tr th:last-of-type .sort-icon-v3 {
  display: none;
}
</style>
