<template>
  <div class="detentions-datatable-container">
    <ActionPanel
      @tab-clicked="setActiveTab"
      class="mb-3"
      :tabs="tabs"
      :show-export="true"
      @on-export="exportFile"
    >
      <template #left-content>
        <CustomDatePicker
          :min-date="minSchoolYearDate"
          :max-date="maxSchoolYearDate"
          v-model="state.form.dateFilter"
          @update:model-value="getData"
        >
        </CustomDatePicker>
      </template>
    </ActionPanel>
    <DataTable :fields="activeTable.fields" :items="activeTable.items">
      <template #image="{ item }">
        <td>
          <img
            class="rounded"
            width="35"
            height="35"
            :src="
              item.user.avatar
                ? item.user.avatar
                : '/img/avatars/user-avatar.jpeg'
            "
          />
        </td>
      </template>
      <template #first_name="{ item }">
        <td>{{ item.user.first_name }}</td>
      </template>
      <template #last_name="{ item }">
        <td>{{ item.user.last_name }}</td>
      </template>
      <template #grade_year="{ item }">
        <td>{{ item.user.grade_year }}</td>
      </template>
      <template #step="{ item }">
        <td>{{ item.step ? item.step : "" }}</td>
      </template>
      <template #status="{ item }">
        <td><DetentionStatusPill :status="item.status" /></td>
      </template>
    </DataTable>
    <Pagination
      v-if="state && state.pagination && state.pagination.pages > 1"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    />
  </div>
</template>

<script>
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import DataTable from "@/v3components/shared/DataTable/DataTable"
import { useStore } from "vuex"
import moment from "moment-timezone"
import { onMounted, computed, reactive } from "vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import DetentionStatusPill from "../shared/Tardy/DetentionStatusPill.vue"
import helpers from "../../helpers/index"
import download from "@/helpers/downloadCSV"

export default {
  name: "DetentionsAndAppointmentsTable",
  components: {
    ActionPanel,
    DataTable,
    CustomDatePicker,
    Pagination,
    DetentionStatusPill
  },
  setup() {
    const store = useStore()

    const state = reactive({
      isProcessing: false,
      activeTab: null,
      form: {
        dateFilter: moment()._d
      },
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      }
    })

    onMounted(() => {
      state.activeTab = tabs[0]
    })

    const activeTable = computed(() => {
      return isDetentionMode.value
        ? {
            items: detentions.value,
            fields: detentionTableFields
          }
        : {
            items: appointments.value,
            fields: appointmentTableFileds
          }
    })

    const detentions = computed(
      () => store.getters["detentionsAppointments/detentions"]
    )
    const appointments = computed(
      () => store.getters["detentionsAppointments/appointments"]
    )
    const isDetentionMode = computed(
      () => state.activeTab && state.activeTab.value === "DETENTION"
    )

    const minSchoolYearDate = computed(() => {
      return helpers.minSchoolYearDate()
    })

    const maxSchoolYearDate = computed(() => {
      return helpers.maxSchoolYearDate()
    })

    const getData = () => {
      const data = {
        date: helpers.currTzDate(state.form.dateFilter),
        type: state.activeTab.value,
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage
      }
      store
        .dispatch("detentionsAppointments/getDetentions", data)
        .then((response) => {
          if (response && response.data && response.data.meta) {
            const data = response.data.meta

            setPagination({
              total: data.total,
              from: data.from,
              to: data.to,
              pages: Math.ceil(data.total / data.per_page),
              activePage: Number(state.pagination.activePage)
            })
          }
        })
    }

    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getData()
    }

    const detentionTableFields = [
      { key: "image", label: "Image", sorter: false, filter: false },
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      { key: "grade_year", label: "Grad year", sorter: false, filter: false },
      {
        key: "step",
        label: "Infraction step",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Detention type",
        sorter: false,
        filter: false
      },
      {
        key: "status",
        label: "Status",
        sorter: false,
        filter: false
      }
    ]

    const appointmentTableFileds = [
      { key: "image", label: "Image", sorter: false, filter: false },
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      { key: "grade_year", label: "Grad year", sorter: false, filter: false },
      {
        key: "step",
        label: "Infraction step",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Appointment type",
        sorter: false,
        filter: false
      }
    ]
    //table
    const tabs = [
      {
        label: "Detentions",
        value: "DETENTION",
        action: getData
      },
      {
        label: "Appointments",
        value: "APT",
        action: getData
      }
    ]
    const setActiveTab = (tab) => {
      state.activeTab = tab
      try {
        tab.action()
      } catch (error) {
        throw new Error(error)
      }
    }
    const exportFile = () => {
      const params = {
        date: moment(state.form.dateFilter).format("YYYY-MM-DD"),
        type: state.activeTab.value,
        page: state.pagination.activePage,
        per_page: [25, 50, 100].includes(state.pagination.per_page.value)
          ? state.pagination.per_page.value
          : "all"
      }
      store
        .dispatch("detentionsAppointments/exportFile", params)
        .then((response) => {
          if (response) {
            download.CSVExport(
              response.data,
              state.activeTab.value === "DETENTION"
                ? "tardy_detentions.csv"
                : "tardy_appointments.csv"
            )
          }
        })
    }

    return {
      tabs,
      setActiveTab,
      minSchoolYearDate,
      maxSchoolYearDate,
      activeTable,
      isDetentionMode,
      exportFile,
      state,
      getData,
      setActivePage
    }
  }
}
</script>

<style></style>
