const passStatusCheckboxes = [
  { label: "System Ended", value: "system_ended" },
  { label: "Extended Time", value: "extended_time" },
  { label: "Canceled Apt", value: "only_canceled_passes" },
  { label: "Missed Apt", value: "only_missed_passes" },
  { label: "Edited", value: "edited" }
]

const tableDropdowns = {
  column: [
    {
      value: "student_id",
      label: "Student ID"
    }
  ],
  tableView: [
    {
      value: false,
      label: "Pass view",
      icon: "ri-list-check-2"
    },
    {
      value: true,
      label: "Tabular view(CSV)",
      icon: "ri-align-justify"
    }
  ]
}

const tableTabs = [
  {
    label: "My passes",
    value: "only_my_passes"
  },
  {
    label: "All passes",
    value: "all_passes"
  }
]

const tableFields = [
  {
    key: "pass_flag",
    label: "F",
    type: "pass-view",
    filter: false,
    sorter: false,
    _style: "max-width:60px; min-width:60px; padding-left: 26px !important;"
  },
  {
    key: "created_date",
    label: "Date",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "total_time",
    label: "Total",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "user",
    label: "Student",
    _style: "min-width:220px",
    type: "pass-view"
  },
  {
    key: "student_sis_id",
    filter: false,
    sorter: false,
    label: "Student number",
    _style: "min-width:150px"
  },
  {
    key: "first_name",
    label: "First name",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "last_name",
    label: "Last name",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "email",
    label: "Email",
    type: "tabular",
    _style: "min-width:150px"
  },

  {
    key: "out_location",
    label: "Out Location",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "out_teacher",
    label: "Out Teacher",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "out_time",
    label: "Out Time",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "in_location",
    label: "In Location",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "in_teacher",
    label: "In Teacher",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "in_time",
    label: "In Time",
    type: "tabular",
    _style: "min-width:150px"
  },

  {
    key: "child_out_location",
    label: "Out Location",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "child_out_teacher",
    label: "Out Teacher",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "child_out_time",
    label: "Out Time",
    type: "tabular",
    _style: "min-width:150px"
  },

  {
    key: "child_in_location",
    label: "In Location",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "child_in_teacher",
    label: "In Teacher",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "child_in_time",
    label: "In Time",
    type: "tabular",
    _style: "min-width:150px"
  },
  { key: "created_date", label: "Date" },
  {
    key: "approved_at",
    _style: "min-width:130px",
    label: "Out time",
    type: "pass-view"
  },
  {
    key: "completed_at",
    _style: "min-width:130px",
    label: "In time",
    type: "pass-view"
  },
  {
    key: "child_approved_at",
    _style: "min-width:130px",
    label: "Out time",
    type: "pass-view"
  },
  {
    key: "child_completed_at",
    _style: "min-width:130px",
    label: "In time",
    type: "pass-view"
  },
  { key: "total_time", label: "Total", type: "pass-view" },
  { key: "type" },
  {
    key: "comments",
    _style: "min-width:160px",
    label: "Status/Comments"
  },
  {
    key: "expired_at",
    label: "Archived date",
    type: "tabular",
    _style: "min-width:150px"
  }
]

export default { passStatusCheckboxes, tableDropdowns, tableTabs, tableFields }
