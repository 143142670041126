<template>
  <div>
    <div class="container">
      <div class="row kiosk justify-content-center mt-4">
        <ActionPanel @tab-clicked="setActiveTab" :tabs="tabs">
          <template #right-content>
            <InfoBox class="action-panel-info-box"
              >Start Kiosk via URL
              <div @click="logOut">
                <a>
                  <span role="button">{{ kioskLoginUrl }}</span>
                </a>
              </div></InfoBox
            >
          </template>
        </ActionPanel>
      </div>
      <div
        v-if="kiosks.length"
        class="row v3-data-table justify-content-center mt-4 mb-4"
      >
        <div class="kiosks-items p-2">
          <div
            class="kiosk-items-element p-2 mb-2"
            v-for="(kiosk, index) in kiosks"
            :key="index"
            :style="{
              'background-color': kiosk.expired_date ? '#CCD6DF' : '#E2EFFC'
            }"
          >
            <div class="kiosk-items-element-left">
              <div class="kiosk-items-element-left-index">
                <b>{{ index + 1 }}</b>
              </div>
              <div class="d-flex flex-column justify-content-center gap-2">
                <div class="kiosk-items-element-left-room-name">
                  <b>{{ kiosk.room ? kiosk.room.name : kiosk.user.name }}</b>
                </div>
                <div
                  data-test-id="kiosk-table-key"
                  v-if="kiosk.passcode"
                  class="kiosk-items-element-left-key"
                >
                  <i class="ri-key-2-line"></i> {{ kiosk.passcode }}
                </div>
              </div>
            </div>
            <div class="kiosk-items-element-right">
              <div
                class="kiosk-items-element-right-status"
                :style="{
                  color: kiosk.expired_date ? '#567593' : '#005DBA'
                }"
              >
                {{ kiosk.expired_date ? "Ended" : "Running" }}
              </div>
              <div
                v-if="!kiosk.expired_date && isKloginActive"
                class="kiosk-items-element-right-launch"
              >
                <span @click="regeneratePasscode(kiosk.id, true)">
                  <i class="ri-device-line"></i> Launch
                </span>
              </div>
              <div class="kiosk-items-element-right-white-box">
                <div class="kiosk-items-element-right-white-box-start">
                  <b>Start time:</b>
                  <span>{{
                    kiosk.passcode_use
                      ? $helpers.transformDate(
                          kiosk.passcode_use,
                          "MM/DD/YYYY h:mm A"
                        )
                      : "--"
                  }}</span>
                </div>
                <div class="kiosk-items-element-right-white-box-end">
                  <b>End time:</b>
                  <span>{{
                    kiosk.expired_date
                      ? $helpers.transformDate(
                          kiosk.expired_date,
                          "MM/DD/YYYY h:mm A"
                        )
                      : "--"
                  }}</span>
                </div>
              </div>
              <div
                v-if="!kiosk.expired_date"
                class="kiosk-items-element-right-actions"
              >
                <Actions
                  data-test-id="kiosk-actions"
                  :actions="kioskActions"
                  @click-action="handleTableAction($event, kiosk.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, inject, watch } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import Actions from "@/v3components/shared/DataTable/Actions"
import helpers from "@/helpers/index"
import router from "@/router"

export default {
  name: "KioskTable",
  components: {
    ActionPanel,
    InfoBox,
    Actions
  },
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")

    const state = reactive({
      isProcessing: false,
      activeTab: null
    })

    const tabs = [
      {
        label: "Active",
        value: "1"
      },
      {
        label: "Inactive",
        value: "0"
      }
    ]

    const kioskActions = [
      {
        label: "Send",
        icon: "ri-send-plane-2-line",
        action: "send"
      },
      {
        label: "Regenerate",
        icon: "ri-refresh-line",
        action: "regenerate"
      },
      {
        label: "End",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const kiosks = computed(() => store.getters["kiosks/kiosks"])

    const activeKioskModuleOptions = computed(
      () => store.getters["schools/activeKioskModuleOptions"]
    )

    const isKloginActive = computed(() => {
      return (
        activeKioskModuleOptions.value && !activeKioskModuleOptions.value.kurl
      )
    })

    watch(
      () => kiosks.value.length,
      () => {
        getKioskStatus()
      }
    )

    onMounted(() => {
      state.activeTab = tabs[0]
    })

    const setActiveTab = (tab) => {
      state.activeTab = tab
      helpers.scrollToTop()
      getKioskStatus()
    }

    const getKioskStatus = () => {
      state.isProcessing = true
      store
        .dispatch("kiosks/getKiosks", { status: state.activeTab.value })
        .then(() => {
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const launch = (code) => {
      if (code) {
        router.push({
          name: "Kiosk Login",
          query: { code }
        })
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "send") {
        sendCode(item)
      }
      if (event.action == "regenerate") {
        regeneratePasscode(item)
      }
      if (event.action == "delete") {
        actionDialog.open(endKiosk, {
          args: item,
          props: {
            danger: true,
            title: "End",
            question: "Are you sure you want to end Kiosk?"
          }
        })
      }
    }

    const sendCode = (kiosk_id) => {
      state.isProcessing = true
      store
        .dispatch("kiosks/sendCode", kiosk_id)
        .then(() => {
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const regeneratePasscode = (kiosk_id, isLaunch) => {
      store
        .dispatch("kiosks/regeneratePasscode", kiosk_id)
        .then((response) => {
          if (isLaunch) {
            const data = response.data.data
            if (data.passcode) {
              launch(data.passcode)
            }
          }
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const endKiosk = (kiosk_id) => {
      store
        .dispatch("kiosks/deactivateKiosk", kiosk_id)
        .then((response) => {
          const data = response.data.data
          if (data) {
            store.commit("kiosks/UPDATE_KIOSK", data)
          }
          setTimeout(() => {
            getKioskStatus()
          }, 1000)
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const logOut = () => {
      const link = document.createElement("a")
      link.href = kioskLoginUrl.value
      link.target = "_blank"
      link.click()
      setTimeout(() => {
        store.dispatch("authentication/unauthorizeIfUserIsLogged")
      }, 2500)
    }

    const kioskLoginUrl = computed(() => {
      return document.location.origin + "/kiosk/login"
    })

    return {
      state,
      tabs,
      kiosks,
      isKloginActive,
      kioskActions,
      setActiveTab,
      getKioskStatus,
      launch,
      handleTableAction,
      regeneratePasscode,
      logOut,
      kioskLoginUrl
    }
  }
}
</script>
