<template>
  <CContainer class="datatable-container">
    <CRow class="justify-content-center mt-2">
      <CCol md="11" class="text-center mb-1">
        <InfoBox title="More on File Uploads">
          <div class="text-start custom-line-height">
            When your file says “Processed” this means it has been received. The
            file(s) then enter a queue to upload those users into our Common
            User Service - "CUS" (or user database). Once users have populated
            the CUS, they will next be synced into your Pass system. This
            process can take anywhere from a few hours to overnight, depending
            on the volume of files in the queue. (If you send your file again it
            will increase the time it takes for users to populate - please do
            not send multiples of the same file). If your file says “Failed”
            there is an issue with the content or format of your file. Please
            check the sample CSV for the required order of columns and headers
            or review the file upload video for more details.
          </div>
        </InfoBox>
      </CCol>
    </CRow>
    <CRow class="justify-content-center">
      <CCol md="11" class="mt-2 mb-2 d-flex justify-content-end">
        <Perpage
          v-if="state.pagination"
          :pagination="state.pagination"
          :showing-entries="true"
          @on-page-change="setPerPage"
        />
      </CCol>
      <CCol md="11">
        <DataTable
          v-if="files"
          class="bg-white"
          :fields="dataTableFields"
          :items="files"
        >
          <template #created="{ item }">
            <td>
              {{
                item.created
                  ? $helpers.transformUTC(item.created, "MM/DD/YYYY h:mm A")
                  : "-"
              }}
            </td>
          </template>
          <template #modified="{ item }">
            <td>
              {{
                item.modified
                  ? $helpers.transformUTC(item.modified, "MM/DD/YYYY h:mm A")
                  : "-"
              }}
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <StatusPill :status="item.status"> </StatusPill>
            </td>
          </template>
        </DataTable>
        <Pagination
          v-if="
            state.pagination.pages &&
            state.pagination.pages > 1 &&
            !state.isLazyLoadingMode
          "
          :active-page="state.pagination.activePage"
          :pages="state.pagination.pages"
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          size="lg"
          align="center"
          @update:active-page="setActivePage"
        ></Pagination>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import StatusPill from "@/v3components/shared/DataTable/StatusPill.vue"
import { onMounted, computed, reactive } from "vue"
import Pagination from "@/v3components/shared/DataTable/Pagination"
import Perpage from "../shared/DataTable/Perpage.vue"
import helpers from "../../helpers/index"

export default {
  name: "FileUploadsTable",
  components: {
    InfoBox,
    DataTable,
    StatusPill,
    Perpage,
    Pagination
  },
  setup() {
    const store = useStore()
    const state = reactive({
      isLazyLoadingMode: false,
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      }
    })

    const dataTableFields = [
      {
        key: "file",
        label: "File Name",
        sorter: false,
        filter: false
      },
      {
        key: "type",
        label: "Type",
        sorter: false,
        filter: false
      },
      {
        key: "created",
        label: "Added Date",
        sorter: false,
        filter: false
      },
      {
        key: "modified",
        label: "Last Change",
        sorter: false,
        filter: false
      },
      { key: "status", label: "Status", sorter: false, filter: false }
    ]

    const files = computed(() => store.getters["files/getFiles"])

    const user = computed(() => store.getters["authentication/user"])

    const setPagination = (data) => {
      state.pagination = Object.assign(state.pagination, data)
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getFiles()
      helpers.scrollToTop()
    }

    const getFiles = () => {
      const params = {
        schoolId: user.value.school_id,
        per_page: state.pagination.per_page.value,
        page: state.pagination.activePage
      }
      store.dispatch("files/getFiles", params).then((response) => {
        if (response && response.data && response.data.meta) {
          const data = response.data
          if (state.isLazyLoadingMode) {
            store.commit("files/PUSH_FILES", data.data)
          } else {
            store.commit("files/SET_FILES", data.data)
          }
          setPagination({
            total: data.meta.total,
            from: data.meta.from,
            to: data.meta.to,
            pages: Math.ceil(data.meta.total / data.meta.per_page),
            activePage: Number(state.pagination.activePage)
          })
        }
      })
    }
    onMounted(() => {
      initLazyLoading()
      getFiles()
    })

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        setActivePage(1)
        getFiles()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            user.value &&
            user.value.length &&
            state.pagination.total > user.value.length
          ) {
            if (body.offsetHeight + body.scrollTop >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              getFiles()
            }
          }
        }
      }
    }

    return {
      state,
      dataTableFields,
      files,
      user,
      getFiles,
      setPerPage,
      setActivePage
    }
  }
}
</script>
