<template>
  <div
    v-if="!isAppoinmentMode"
    class="status-guide order-3 order-lg-1 mt-3 mt-lg-1"
  >
    <div class="me-3">Filters:</div>
    <div class="status-list">
      <CCheckBox
        :disabled="activePassState === 'ended'"
        label="Waiting Approval"
        :checked="filterData.waiting_approval"
        name="waiting_approval"
        :inline="true"
        :custom="true"
        class="custom-checkbox edited"
        @change="updateFilters"
      />
      <CCheckBox
        v-if="passesTimeSettings && passesTimeSettings.min_time"
        :label="
          $helpers.formatDate(passesTimeSettings.min_time, 'hh:mm:ss', 'mm') +
          '+Min'
        "
        :checked="filterData.yellow_time"
        name="yellow_time"
        :inline="true"
        :custom="true"
        class="custom-checkbox min-10"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="System Ended"
        :checked="filterData.system_ended"
        name="system_ended"
        :inline="true"
        :custom="true"
        class="custom-checkbox system-ended"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="Extended Passes"
        :checked="filterData.extended_time"
        name="extended_time"
        :inline="true"
        :custom="true"
        class="custom-checkbox extended-passes"
        @change="updateFilters"
      />
      <CCheckBox
        :disabled="activePassState === 'active'"
        label="Edited"
        :checked="filterData.edited"
        name="edited"
        :inline="true"
        :custom="true"
        class="custom-checkbox gray"
        @change="updateFilters"
      />
      <CCheckBox
        label="Student Number"
        :checked="filterData.student_sis_id"
        name="student_sis_id"
        class="custom-checkbox"
        @change="updateFilters"
        :inline="true"
        :custom="true"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTimeFiltersOld",
  setup() {
    const store = useStore()

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )

    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )

    const filterData = computed(
      () => store.getters["dashboardTable/filterData"]
    )

    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )

    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )

    const isAppoinmentMode = computed(() => {
      return activePassType.value.includes("appointment")
    })

    const updateFilters = (event) => {
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", {
        filter: event.target.name,
        value: event.target.checked
      })
    }

    return {
      isActiveAppoinmentModule,
      passesTimeSettings,
      activeSchoolHasAppoitmentPasses,
      filterData,
      activePassState,
      activePassType,
      isAppoinmentMode,
      updateFilters
    }
  }
}
</script>
