<template>
  <CContainer fluid class="appointment-pass-view datatable-container mb-5">
    <Loader :is-processing="isLoading" />
    <div>
      <CCol md="12">
        <div class="d-flex justify-content-end">
          <PerPage
            v-if="state.pagination && state.pagination.total > 0"
            :pagination="state.pagination"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </div>

        <DataTable
          v-if="appointmentPasses"
          class="mt-3"
          add-table-classes="app-pass-datatable"
          :items="appointmentPasses"
          :fields="activeDataTableFields"
          :sorter="{ external: true }"
          column-filter
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          @update:sorter-value="sortByColumn"
        >
          <template #search_column>
            <div class="sort-icon-v3">
              <i class="ri-search-line" @click="toggleSearchByColumn"></i>
            </div>
          </template>

          <template #user="{ item }">
            <td>
              <UserAptPass :pass="item" :appointments="true" />
            </td>
          </template>
          <template #user-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.user.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #student_sis_id="{ item }">
            <td>
              <div class="pass-time-info">
                {{
                  item.user && item.user.student_sis_id
                    ? item.user.student_sis_id
                    : ""
                }}
              </div>
            </td>
          </template>

          <template #recurrence_appointment_pass="{ item }">
            <td>
              <div v-if="item.recurrence_appointment_pass">
                <div>
                  <b>{{
                    $helpers.transformDate(item.for_date, "MMM DD YYYY")
                  }}</b>
                </div>
                <div
                  class="d-flex v3base-gray"
                  v-if="item.recurrence_appointment_pass.recurrence_type"
                >
                  <div class="me-2 mt-sm-1">
                    {{ item.recurrence_appointment_pass.recurrence_type }}
                  </div>
                  <div
                    v-if="item.recurrence_appointment_pass.recurrence_days"
                    class="weekdays-options-container d-flex"
                  >
                    <div
                      v-for="(day, index) in JSON.parse(
                        item.recurrence_appointment_pass.recurrence_days
                      ).days"
                      :key="index"
                      class="weekday-box active me-1"
                    >
                      {{ day.substring(0, 2) }}
                    </div>
                  </div>
                </div>
                <div
                  class="font-smaller v3base-gray"
                  v-if="activeTab == 'nextSevenDays'"
                >
                  <div
                    v-if="item.recurrence_appointment_pass.recurrence_end_at"
                    class=""
                  >
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.for_date,
                        "MMM D YYYY"
                      )
                    }}
                    <i class="ri-arrow-right-s-line v3base-blue"></i>
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.recurrence_end_at,
                        "MMM D YYYY"
                      )
                    }}
                  </div>
                </div>
                <div v-else>
                  <div v-if="item.for_date" class="font-smaller v3base-gray">
                    {{ $helpers.transformDate(item.for_date, "MMM D YYYY") }}
                    <i class="ri-arrow-right-s-line v3base-blue"></i>
                    {{
                      $helpers.transformDate(
                        item.recurrence_appointment_pass.recurrence_end_at,
                        "MMM D YYYY"
                      )
                    }}
                  </div>
                </div>
              </div>
              <div v-else>
                <b class="mt-0">{{
                  item.for_date
                    ? $helpers.transformDate(item.for_date, "MMM D YYYY")
                    : ""
                }}</b>
                <div class="v3base-gray">Does not repeat</div>
              </div>
            </td>
          </template>
          <template #recurrence_appointment_pass-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.recurrence_appointment_pass.value"
              placeholder="Search by date"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #period="{ item }">
            <td>
              <div v-if="item.period">
                <b>{{ item.period.name }}</b>
                <p class="mb-0">
                  {{
                    item.for_date
                      ? helpers.transformDate(item.for_date, "h:mm A")
                      : ""
                  }}
                </p>
              </div>
            </td>
          </template>
          <template #period-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.period.value"
              placeholder="Search by period name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #from="{ item }">
            <td class="pass-time-info">
              <div class="d-flex">
                <b
                  v-if="
                    item.from_type && item.from_type === 'App\\Models\\User'
                  "
                  >{{
                    item.from
                      ? item.from.first_name + " " + item.from.last_name
                      : "-"
                  }}</b
                >
                <b v-else>{{ item.from ? item.from.name : "-" }}</b>
                <b
                  v-if="
                    item.acknowledged_by_user &&
                    item.from &&
                    item.acknowledged_by_user.id === item.from.id
                  "
                  ><span class="checked-icon ms-2"></span
                ></b>
              </div>
            </td>
          </template>
          <template #from-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.from.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #to="{ item }">
            <td class="pass-time-info">
              <b v-if="item.to && item.to === 'App\\Models\\User'">{{
                item.to ? item.to.first_name + " " + item.to.last_name : "-"
              }}</b>
              <b v-else>{{ item.to ? item.to.name : "-" }}</b>
              <b
                v-if="
                  item.acknowledged_by_user &&
                  item.to &&
                  item.acknowledged_by_user.id === item.to.id
                "
                ><span class="checked-icon ms-2"></span
              ></b>
            </td>
          </template>
          <template #to-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.to.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #created_by_user="{ item }">
            <td>
              <div v-if="item.created_by_user">
                <b>{{
                  item.created_by_user.first_name +
                  " " +
                  item.created_by_user.last_name
                }}</b>
                <p v-if="item.created_at">
                  {{ helpers.transformDate(item.created_at, "MMM DD YYYY") }}
                  <br />
                  {{ helpers.transformDate(item.created_at, "h:mm A") }}
                </p>
              </div>
            </td>
          </template>
          <template #created_by_user-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.created_by_user.value"
              placeholder="Search by name"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #comments="{ item }">
            <td>
              <div>
                <p class="mb-1">
                  <b
                    >{{
                      item.created_by_user && item.created_by_user.role_id == 1
                        ? "Requested "
                        : "Made "
                    }}
                  </b>
                  <span
                    v-html="
                      helpers.getHTMLDateTime(
                        item.created_at,
                        'MMM DD YYYY',
                        'h:mm A'
                      )
                    "
                  ></span>
                </p>
                <p
                  v-if="
                    item.acknowledged_by_teacher_at &&
                    item.created_by_user.role_id != 1
                  "
                  class="mb-1"
                >
                  <b>Acknowledged </b>
                  <span
                    v-html="
                      helpers.getHTMLDateTime(
                        item.acknowledged_by_teacher_at,
                        'MMM DD YYYY',
                        'h:mm A'
                      )
                    "
                  ></span>
                </p>
                <p
                  v-if="
                    item.confirmed_by_teacher_at &&
                    item.acknowledged_by_teacher_at &&
                    item.created_by_user.role_id == 1
                  "
                  class="mb-1"
                >
                  <b>Confirmed </b>
                  <span
                    v-html="
                      helpers.getHTMLDateTime(
                        item.confirmed_by_teacher_at,
                        'MMM DD YYYY',
                        'h:mm A'
                      )
                    "
                  ></span>
                </p>
                <p
                  v-if="item.canceled_at && item.canceled_by_user"
                  class="mb-1"
                >
                  <b
                    >Canceled by {{ item.canceled_by_user.first_name }}
                    {{ item.canceled_by_user.last_name }} at
                  </b>
                  <span
                    v-html="
                      $helpers.getHTMLDateTime(
                        item.canceled_at,
                        'MMM DD YYYY',
                        'h:mm A'
                      )
                    "
                  ></span>
                </p>
              </div>
              <PassComments
                :pass="item"
                commentable-type="App\Models\AppointmentPass"
                style="cursor: pointer"
              />
            </td>
          </template>
          <template #comments-filter>
            <input
              v-show="state.filter"
              v-model="state.columnSearchKeys.comments.value"
              placeholder="Search by latest comment"
              class="form-control form-control-sm my-2"
              @input="searchByQuery($event.target.value, 'column')"
            />
          </template>

          <template #actions="{ item }">
            <td class="text-end">
              <AptPassAction
                :pass="item"
                :is-pass-beyond="activeTab === 'prevSevenDays'"
              />
            </td>
          </template>
          <template #actions-filter>
            <div v-show="state.filter"></div>
          </template>
        </DataTable>

        <Pagination
          v-if="
            pagination.pages &&
            pagination.pages > 1 &&
            pagination.per_page.value != 75
          "
          class="cs-pagination mt-4"
          :dots="false"
          :double-arrows="true"
          :active-page="pagination.activePage"
          :pages="pagination.pages"
          size="lg"
          align="center"
          @update:active-page="setActivePage($event)"
        >
          <template #last-button>
            <i class="flaticon-right-arrow-1" />
          </template>
          <template #first-button>
            <i class="flaticon-left-arrow-2" />
          </template>
          <template #previous-button>
            <i class="fi flaticon-left-arrow-1" />
          </template>
          <template #next-button>
            <i class="fi flaticon-chevron" />
          </template>
        </Pagination>
      </CCol>
    </div>
  </CContainer>
</template>

<script>
import { reactive, computed, onMounted, watch } from "vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import helpers from "@/helpers/index"
import Loader from "@/v3components/shared/Loader/Loader"
import tableConfig from "../AdultAptPassDataTable/appointmentsTableConfig"
import PerPage from "@/v3components/shared/DataTable/Perpage"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import UserAptPass from "@/v3components/Datatables/AdultAptPassDataTable/UserAptPass"
import PassComments from "@/v3components/shared/PassComponents/PassComments"
import AptPassAction from "@/v3components/Datatables/AdultAptPassDataTable/AptPassAction"

export default {
  name: "AdultAptPassDataTableV6",
  components: {
    Loader,
    PerPage,
    DataTable,
    UserAptPass,
    PassComments,
    AptPassAction
  },
  setup() {
    const store = useStore()
    const state = reactive({
      ...tableConfig,
      isLoading: false,
      isLazyLoadingMode: false,
      filter: false,
      pagination: {
        activePage: 1,
        total: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      waiting: false
    })

    // ** Computed **
    const periods = computed(() => {
      return store.getters["periods/formListStudents"]
    })

    const editablePass = computed(() => {
      return store.getters["adultAptPass/editablePass"]
    })

    const activeTab = computed(() => {
      return store.getters["adultAptPass/activeTab"]
    })

    const pagination = computed(() => {
      return store.getters["adultAptPass/pagination"]
    })

    const searchQuery = computed(() => {
      return store.getters["adultAptPass/searchQuery"]
    })

    const appointmentPasses = computed(() => {
      return store.getters["adultAptPass/appointmentPasses"]
    })

    const currentUser = computed(() => {
      return store.getters["authentication/user"]
    })

    const activePassType = computed(() => {
      return store.getters["dashboardTable/getType"]
    })

    const globalTime = computed(() => {
      return store.getters["layout/globalTime"]
    })

    const isActiveAppoinmentModule = computed(() => {
      return store.getters["schools/isActiveAppoinmentModule"]
    })

    const filterData = computed(() => {
      return store.getters["dashboardTable/aptFilterData"]
    })

    const editablePassUserName = computed(() => {
      return editablePass.value && editablePass.value.user
        ? editablePass.value.user.first_name +
            " " +
            editablePass.value.user.last_name
        : null
    })

    const dataTableFieldsApt = computed(() => {
      return state.dataTableFields.toSpliced(1, 0, {
        key: "student_sis_id",
        filter: false,
        sorter: false,
        label: "Student number",
        _style: "min-width:150px"
      })
    })

    const activeDataTableFields = computed(() => {
      return dataTableFieldsApt.value.filter((field) =>
        !filterData.value.student_sis_id
          ? field.key !== "student_sis_id"
          : field
      )
    })

    onMounted(() => {
      store.dispatch("schools/getActiveModules")
      store.commit("adultAptPass/SET_PASS_TYPE", {
        label: "All Types",
        value: ""
      })
      resetSearchQuery()
      if (isActiveAppoinmentModule.value) {
        getAptBaseOnActiveDashboardTab()
      }

      initLazyLoading()
    })

    // ** Watch **
    watch(
      () => activePassType.value,
      () => {
        if (isActiveAppoinmentModule.value) {
          getAptBaseOnActiveDashboardTab()
        }
      }
    )

    watch(
      () => globalTime.value,
      () => {
        const startLength = appointmentPasses.value.length
        store.commit(
          "adultAptPass/SET_APPOINTMENT_PASSES",
          appointmentPasses.value
        )
        const endLength = appointmentPasses.value.length
        if (startLength != endLength) {
          store.dispatch("dashboardTable/getDashboard")
          if (isActiveAppoinmentModule.value) {
            getAptBaseOnActiveDashboardTab()
          }
        }
      }
    )

    // ** Methods **
    const setActivePage = (page) => {
      helpers.scrollToTop()
      store.commit("adultAptPass/SET_ACTIVE_PAGE", page)
    }

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        store.commit("adultAptPass/SET_PER_PAGE", option)
      }
    }

    const getAptBaseOnActiveDashboardTab = () => {
      state.isLoading = true
      if (activePassType.value == "appointments_today") {
        store.commit("adultAptPass/SET_BETWEEN_DATES", [
          moment().format("MM/DD/YYYY").toString(),
          moment().format("MM/DD/YYYY").toString()
        ])
        store.commit("adultAptPass/SET_QUICK_FILTER", {
          label: "Today's Apt",
          value: "todays_dashboard"
        })
      }
      if (activePassType.value == "appointments_awaiting") {
        store.commit("adultAptPass/SET_BETWEEN_DATES", [
          moment().format("MM/DD/YYYY").toString(),
          moment().format("MM/DD/YYYY").toString()
        ])
        store.commit("adultAptPass/SET_QUICK_FILTER", {
          label: "Today's Apt",
          value: "awaiting"
        })
      }
      if (activePassType.value == "appointments_future_awaiting") {
        store.commit("adultAptPass/SET_BETWEEN_DATES", [
          moment().add(1, "days").format("MM/DD/YYYY").toString(),
          moment().add(1, "years").format("MM/DD/YYYY").toString()
        ])
        store.commit("adultAptPass/SET_QUICK_FILTER", {
          label: "Today's Apt",
          value: "awaiting"
        })
      }
      store.commit("adultAptPass/SET_SEARCH_QUERY", "")
      store.commit("adultAptPass/SET_RECURRENCE", false)
      state.columnSearchKeys = {
        globalQuery: "",
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        recurrence_appointment_pass: {
          value: "",
          column: ["search_date_string"],
          sortBy: "for_date"
        },
        period: { value: "", column: ["period.name"], sortBy: "period.name" },
        from: { value: "", column: ["from.name"], sortBy: "from.name" },
        to: { value: "", column: ["to.name"], sortBy: "to.name" },
        created_by_user: {
          value: "",
          column: ["created_by_user.name"],
          sortBy: "created_by_user.name"
        },
        comments: {
          value: "",
          column:
            activeTab.value == "prevSevenDays"
              ? ["reason"]
              : ["latest_comment.comment"],
          sortBy: "latest_comment.comment"
        }
      }
      store.dispatch("adultAptPass/getAppointmentPasses").then((response) => {
        const data = response.data
        if (data.meta) {
          state.pagination.total = data.meta.total
          state.pagination.from = data.meta.from
          state.pagination.to = data.meta.to
          state.pagination.pages = Math.ceil(
            data.meta.total / data.meta.per_page
          )
          state.pagination.activePage = Number(state.pagination.activePage)
          state.pagination = JSON.parse(JSON.stringify(state.pagination))
        }
        state.isLoading = false
      })
    }

    const searchByQuery = (value, type) => {
      if (window.searchByQueryTimeout) clearTimeout(window.searchByQueryTimeout)

      window.searchByQueryTimeout = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 1000)
    }

    const handleQuerySearch = (value, type) => {
      state.isLoading = true
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        store.commit("adultAptPass/SET_SEARCH_QUERY", "")
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  if (searchQuery.value) {
                    store.commit(
                      "adultAptPass/SET_SEARCH_QUERY",
                      searchQuery.value + `,${col}:${columnData.value}`
                    )
                  } else {
                    store.commit(
                      "adultAptPass/SET_SEARCH_QUERY",
                      `${col}:${columnData.value}`
                    )
                  }
                })
              }
            }
          }
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          if (query) {
            store.commit("adultAptPass/SET_SEARCH_QUERY", query)
          }
        }
        store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
        store.dispatch("adultAptPass/getAppointmentPasses").then(() => {
          state.isLoading = false
        })
      }
    }

    const resetSearchQuery = () => {
      store.commit("adultAptPass/SET_SEARCH_QUERY", "")
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      state.columnSearchKeys = {
        globalQuery: "",
        user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
        recurrence_appointment_pass: {
          value: "",
          column: ["search_date_string"],
          sortBy: "for_date"
        },
        period: { value: "", column: ["period.name"], sortBy: "period.name" },
        from: { value: "", column: ["from.name"], sortBy: "from.name" },
        to: { value: "", column: ["to.name"], sortBy: "to.name" },
        created_by_user: {
          value: "",
          column: ["created_by_user.name"],
          sortBy: "created_by_user.name"
        },
        comments: {
          value: "",
          column:
            activeTab.value == "prevSevenDays"
              ? ["reason"]
              : ["latest_comment.comment"],
          sortBy: "latest_comment.comment"
        }
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      store.commit(
        "adultAptPass/SET_SORT_BY",
        `${columnName}:${column.asc ? "asc" : "desc"}`
      )
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
    }

    const setEditablePass = (pass) => {
      store.commit("adultAptPass/SET_EDITABLE_PASS", pass)
    }

    const onPassEdit = (pass) => {
      state.modal.isVisible.editApptPass = true
      helpers.scrollToTop()
      setTimeout(() => {
        store.commit("adultAptPass/SET_EDITABLE_PASS", pass)
      }, 500)
    }

    const exportCSVDashboardData = () => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/getCSVAppointmentPasses", {
          student_sis_id: filterData.value.student_sis_id
        })
        .then((response) => {
          if (response.data) {
            helpers.CSVExport(response.data, "appointment-passes")
          }
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            passes.value &&
            passes.value.length &&
            state.pagination.total > passes.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              store.commit(
                "adultAptPass/SET_ACTIVE_PAGE",
                pagination.value.activePage + 1
              )
            }
          }
        }
      }
    }

    const closeEditModal = () => {
      state.modal.isVisible.editApptPass = false
      if (this.$refs.CreateAppoinmentsPassForm) {
        this.$refs.CreateAppoinmentsPassForm.cancelEdit()
      }
    }

    return {
      state,
      helpers,
      periods,
      editablePass,
      activeTab,
      pagination,
      searchQuery,
      appointmentPasses,
      currentUser,
      activePassType,
      globalTime,
      isActiveAppoinmentModule,
      filterData,
      editablePassUserName,
      activeDataTableFields,
      setActivePage,
      toggleSearchByColumn,
      setPerPage,
      getAptBaseOnActiveDashboardTab,
      searchByQuery,
      handleQuerySearch,
      resetSearchQuery,
      sortByColumn,
      // closeModal,
      // setActiveModal,
      setEditablePass,
      onPassEdit,
      exportCSVDashboardData,
      closeEditModal
    }
  }
}
</script>
